import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Slice } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentNode, useEntity, useEntityOptions } from '@shapeable/ui';
import { ContentTitle } from '../elements/content-title';
import { OrganisationLink } from './organisation-link';

/** Lays out the Content and Intro Side by Side */

// -------- Types -------->

export type SliceNarrativeProps = Classable & HasChildren & { 
  entity?: Slice;
  reverse?: boolean;
};

export const SliceNarrativeDefaultProps: Omit<SliceNarrativeProps, 'entity'> = {
};

// -------- Child Component Props -------->

type LayoutProps = {
  reverse: boolean;
  hasIntro: boolean;
}

type IntroProps = {
  largeIntro: boolean;
  hasContent: boolean;

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding: 0;
    color: ${theme.COLOR('text')};
  `,
});

const IntroStyles = breakpoints({
  base: css`
    box-sizing: border-box;
    color: ${theme.COLOR('heading')};
    font-size: ${20/15}em;
    line-height: 1.35em;
    font-family: ${theme.FONT('serif')}; 
    margin-bottom: ${theme.UNIT(6)};

    ${({ largeIntro }: IntroProps ) => largeIntro && css`
      font-size: ${28/15}em;
    `}
  `,
  desktop: css`
    ${({ hasContent }: any ) => hasContent && css`
      width: 50%;
    `}
    font-size: ${24/15}em;
    margin-bottom: 0;
    ${({ reverse }: LayoutProps ) => css`
      padding-left: ${theme.UNIT(reverse ? 6 : 0)};
      padding-right: ${theme.UNIT(reverse ? 0 : 6)};
    `}

    ${({ hasContent }: IntroProps ) => !hasContent && css`
      padding-right: 0;
      padding-left: 0;
    `}

  `,
});

const ContentStyles = breakpoints({
  base: css`
    box-sizing: border-box;
    font-weight: 300;
    line-height: 1.45em;
    ${({ reverse, hasIntro }: LayoutProps ) => hasIntro && css`
      padding-right: ${theme.UNIT(reverse ? 6 : 0)};
      padding-left: ${theme.UNIT(reverse ? 0 : 6)};
    `}

  `,
  desktop: css`
    ${({ hasIntro }: LayoutProps ) => hasIntro && css`
      width: 50%;
    `}
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    padding: 0;
  `,
  tablet: css`
 `,
  desktop: css`
    align-items: flex-start;
    flex-direction: row;

    ${({ reverse }: LayoutProps ) => reverse && css`
      flex-direction: row-reverse;
    `}
  `
});

const TitleStyles = breakpoints({
  base: css`
    margin-top: 0;
  `,
});

const OrganisationsStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(8)};
    display: flex;
    align-items: center;
    flex-direction: column;
  `,
  tablet: css`
    flex-direction: row;
  `,
});

const OrganisationStyles = breakpoints({
  base: css`
    width: 240px;
    margin-bottom: ${theme.UNIT(8)};
  `,
  tablet: css`
    margin-bottom: 0;
    margin-right: ${theme.UNIT(16)};
  `
});


// -------- Components -------->

const My = {
  Container: styled.div<LayoutProps>`${ContainerStyles}`,
  Title: styled(ContentTitle)`${TitleStyles}`,
    Body: styled.div<LayoutProps>`${BodyStyles}`,
    Intro: styled(ContentNode)<IntroProps>`${IntroStyles}`,
    Content: styled(ContentNode)<LayoutProps>`${ContentStyles}`,

    Organisations: styled.div`${OrganisationsStyles}`,
      Organisation: styled(OrganisationLink)`${OrganisationStyles}`,
};

export const SliceNarrative: React.FC<SliceNarrativeProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const { intro, content, title, subtitle, organisations = [] } = entity;
  
  const hasIntro = !!intro?.text;
  const hasContent = !!content?.text;

  const reverse = hasIntro ? props.reverse : false; 

  const layoutProps: LayoutProps = { hasIntro, reverse };

  const hasOrganisations = !!organisations.length;
  
  const { largeIntro } = useEntityOptions(entity);

  return (
    <My.Container {...layoutProps} className={className}>
    {
      title &&
      <My.Title>{title}</My.Title>
    }
    <My.Body {...layoutProps}>
    {
      intro && <My.Intro hasContent={hasContent} largeIntro={largeIntro} {...layoutProps} entity={intro} />  
    }
    {
      content && <My.Content {...layoutProps} entity={content} />
    }
    </My.Body>
    {
      hasOrganisations &&
      <My.Organisations>
      {
        organisations.map(organisation => <My.Organisation key={organisation.id} entity={organisation} />)
      }
      </My.Organisations>
    }
    {children}
    </My.Container>
  )
};

SliceNarrative.defaultProps = SliceNarrativeDefaultProps;
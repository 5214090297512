import { classNames, coalesce, firstStringProperty } from '@shapeable/utils';
import React from 'react';
import styled, { css } from 'styled-components'; const cls = classNames('organisation-pledge-value');
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellLabel, Icon, useEntity } from '@shapeable/ui';
import { PledgeCircle } from '../elements/pledge-circle';
import { OrganisationPledge } from '@shapeable/kelp-forest-alliance-types';
import { ORANGE } from '../../theme';
import { get } from 'lodash';
import { PledgeIcon } from '@shapeable/icons';

// -------- Types -------->

export type OrganisationPledgeValueProps = Classable & HasChildren & { 
  entity?: OrganisationPledge;
};

export const OrganisationPledgeValueDefaultProps: Omit<OrganisationPledgeValueProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    border-radius: 100px;
    align-items: center;
    background: ${theme.COLOR('line-very-subtle')};
    border: 1px solid ${theme.COLOR('line-subtle')};
    padding: ${theme.UNIT(2)} ${theme.UNIT(4)} ${theme.UNIT(2)} ${theme.UNIT(3)};
    color: ${theme.COLOR('text')};
  `,
});

const PledgeCircleStyles = breakpoints({
  base: css`
    
  `,
});


const BodyStyles = breakpoints({
  base: css`
    padding-left: ${theme.UNIT(2)};
    flex-shrink: 1;
  `,
});

const MetricNameStyles = breakpoints({
  base: css`
    line-height: 1.2em;
    margin-bottom: ${theme.UNIT(1)};
  `,
});

const ValueStyles = breakpoints({
  base: css`
    span {
      margin-right: ${theme.UNIT(1)};
    }
  `,
});

const IconContainerStyles = breakpoints({
  base: css`
    flex-shrink: 0;
  `,
});





// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    IconContainer: styled(Icon)`${IconContainerStyles}`,
      Icon: styled(PledgeIcon)`${PledgeCircleStyles}`,
    Body: styled.div`${BodyStyles}`,
      MetricName: styled(CellLabel)`${MetricNameStyles}`,
      Value: styled.b`${ValueStyles}`,
};

export const OrganisationPledgeValue: React.FC<OrganisationPledgeValueProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  const metricName = firstStringProperty(entity, ['metric.title', 'metric.name']);
  const unitName = firstStringProperty(entity, ['metric.unit.name', 'metric.unit.shorthand']);

  return (
    <My.Container className={cls.name(className)}>
      {/* <My.Icon size={48} colors={{ fill: ORANGE }} /> */}
      <My.Icon size={48} colors={{ fill: ORANGE, hover: { fill: ORANGE }}} />
      <My.Body>
        <My.MetricName>{metricName}</My.MetricName>
        <My.Value>
          {
            entity.value && <span>{entity.value}</span>
          }
          {unitName ? `(${unitName})` : ''}</My.Value>
      </My.Body>
    </My.Container>
  )
};

OrganisationPledgeValue.defaultProps = OrganisationPledgeValueDefaultProps;
import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { platformProviderValue, PostsProvider as Provider } from "@shapeable/ui";


const QUERY = graphql`
  query PostsQuery {
    platform {
      allPosts { 
        id name path slug __typename
        _schema { label pluralLabel }
        intro { plain }
        content { text(truncate: { words: 20 }) }
        title
        openGraph { image { url url2x thumbnails { card { url url2x } bubble { url url2x } bubbleLarge { url url2x } } } }
        type { id name slug __typename }
        published
        people {
          id name slug path 
          organisation { id name }
          linkedin
          position
          openGraph { image { url url2x thumbnails { card { url url2x } bubble { url url2x } bubbleLarge { url url2x } } } }
        }
        authors {
          openGraph { image { url url2x thumbnails { card { url url2x } bubble { url url2x } bubbleLarge { url url2x } } } }
          _schema { label pluralLabel }
          id name slug path
          organisation { id name }
          linkedin
        }
      }
    }
  }
`

export const PostsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allPosts' ) }>{children}</Provider>;
}